import React, {Component} from "react";

import {withRouter, Link, Redirect} from "react-router-dom";
import {Modal} from "reactstrap";
import axios from "axios";
import {SyncClient} from "twilio-sync";
import _ from "lodash";

import {isFocused, notify} from "../../utils/notifier";
import {CHAT_KEY, isLoggedIn, isMobile, isMediaSupported, getServices, loadScript} from "../../utils/utils";

import logo2 from "../../images/logo2.png";

import Notifications from "../Notifications";
import ProfileDropdown from "./ProfileDropdown";
import SettingsDropdown from "./SettingsDropdown";
import MessengerDropdown from "./MessengerDropdown";
import {CallContext} from "../../utils/call-context";

import { THEME } from "../NewSettingsComponents";

import "../../styles/Header.css";
import {X} from "react-feather";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            onMobile: isMobile(),

            loggedIn: false,
            gotLoggedIn: false,
            isOpen: false,

            modal: false,
            modalMsg: "",

            modal2: false,
            modal2Msg: "",

            onYes: null,
            onNo: null,

            returnToHome: false,

            goToChat: false,
            chatParams: null,
            isDatingAccount: false,
        };

        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.setMobile = this.setMobile.bind(this);
        this.checkLogin = this.checkLogin.bind(this);
        this.handleYesNo = this.handleYesNo.bind(this);
    }

    checkLogin(cb) {
        isLoggedIn((newVal) => {
            this.setState({loggedIn: newVal, gotLoggedIn: true}, cb);
            if (newVal) {
                getServices((res) => {
                    if (res !== null) {
                        this.setState({isDatingAccount: res.data.datingAccount});
                    }
                });
            }
        });
    }

    handleYesNo(yes) {
        this.setState({modal2: false}, () => {
            const cb = yes ? this.state.onYes : this.state.onNo;

            if (cb) {
                cb();
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({goToChat: false});
            if (this.state.isOpen) {this.toggle();}
        }
    }

    setMobile() {
        this.setState({
            onMobile: isMobile(),
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
        const fail = () => console.log("Failed loading script");
        this.checkLogin(() => {
            if (this.state.loggedIn) {
                loadScript("/socket.io/socket.io.js")
                    .then(() => {
                        loadScript("/easyrtc/easyrtc.js")
                            .then(() => this.connectToRTC())
                            .catch(fail);
                    })
                    .catch(fail);
            }
        });
    }

    connectToRTC() {
        if (window.easyrtc) {
            console.log("Connecting to messaging server");
            window.easyrtc.connect("morphkey",
                (rtcId) => {
                    window.sessionStorage.setItem(CHAT_KEY, rtcId);

                    axios.post("/api/rtcID/" + rtcId, {video: isMediaSupported()}).then((result) => {
                        this.setupCallSignalling(result.data.id);
                        const rtcIDs = [];

                        result.data.allIds.forEach((rtcID) => {
                            if (!window.easyrtc.isPeerInAnyRoom(rtcID)) {
                                rtcIDs.push(rtcID);
                            }
                        });

                        axios.post("/api/rtcID/remove-batch", {rtcID: rtcIDs}).catch(() => {});
                    });
                },
                (code) => {
                    let text = "Connection failed";

                    if (code === "MEDIA_ERR") {
                        text += ". Cannot find a local web camera";
                    }

                    if (code === "MEDIA_WARNING") {
                        text += ". Video width and height are inappropriate";
                    }

                    if (code === "SYSTEM_ERR") {
                        text += ". Check your network settings";
                    }

                    if (code === "ALREADY_CONNECTED") {
                        text += ". You are already connected";
                    }

                    console.log(text);
                }
            );
        }
    }

    // Allows call signals to be sent from another user throughout app
    setupCallSignalling(userId) {
        axios.get("/api/twilio-sync-auth/").then((result) => {
            const token = result.data.token;
            const syncClient = new SyncClient(token, { logLevel: "info" });
            let clientInitialized = false;
            this.context.setCallSignals({ signallingClient: syncClient });

            syncClient.document(userId).then((document) => {
                if (!clientInitialized) {
                    document.set({});   // Clear signalling
                    clientInitialized = true;
                }

                document.on("updated", () => {
                    const callSignals = document.data;

                    if (callSignals.callerRtcId) {
                        // Prompt user to accept call request
                        console.log("Another user is calling.");
                        this.context.setCallSignals({ userCalling: callSignals.callerRtcId });

                        // Prompt from Header component unless Chat component is mounted
                        if (!this.props.location.pathname.includes("chat")) {
                            this.promptCallRequest(callSignals.callerRtcId);
                            this.context.setCallSignals({ userCalling: null });
                        }
                    }

                    if (callSignals.videoRoom) {
                        // Call accepted, join room
                        console.log("Joining chat room.");
                        this.context.setCallSignals({ callRoomId: callSignals.videoRoom });
                    }

                    if (callSignals.declined) {
                        // Call declined
                        console.log("The other user declined call.");
                        this.context.setCallSignals({ callDeclined: callSignals.declined });
                    }

                    if (callSignals.connMsgSenderID) {
                        this.context.setCallSignals({ connectionMsg: callSignals.connMsgSenderID });
                    }

                    if (callSignals.reschMsgSenderID) {
                        this.context.setCallSignals({ rescheduleMsg: callSignals.reschMsgSenderID });
                    }

                    if (!_.isEmpty(callSignals)) {
                        document.set({});
                    }
                });
            });
        });
    }

    promptCallRequest(callerRtcId) {
        axios.get("/api/check-outgoing/" + callerRtcId).then((res) => {
            if (res.data.chatParams) {
                const onYes = () => {
                    this.setState({ goToChat: true, chatParams: res.data.chatParams });
                    this.context.setCallSignals({ callTransferred: true });
                };

                const onNo = () => {
                    this.context.setCallSignals({ userCalling: null });
                    this.context.signallingClient.document(res.data.chatParams.activeID).then((document) => {
                        document.update({ declined: true });
                    });
                };

                const name = res.data.chatParams.activeName;
                const modal2Msg = "Accept call from " + name + "?";

                if (!isFocused()) {
                    notify({
                        vibrate: [200, 100, 200],
                        body: `${name} is calling you!`,
                        icon: `${process.env.PUBLIC_URL}/favicon.ico`,
                    });
                }

                this.setState({
                    modal2: true,
                    modal2Msg,
                    onYes, onNo,
                });
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    leaveAndGoHome = async () => {
        if (window.easyrtc) {
            console.log("Disconnecting from chat server");

            window.easyrtc.hangupAll();
            window.easyrtc.disconnect();
        }

        await axios.post(`/api/rtcid/remove/${window.sessionStorage.getItem(CHAT_KEY)}`).catch(() => {});
        await axios.post("/api/logout", {}, {withCredentials: true});

        this.setState({
            returnToHome: true,
            loggedIn: false,
            gotLoggedIn: false,
            activeTab: -1
        }, () => {
            if (window.location.pathname === "/") {
                window.location.reload();
            } else {
                window.location.href = "/";
            }
        });
    };

    logout() {
        if (this.context.onCall) {
            this.setState({
                modal2: true,
                modal2Msg: "Logout and drop current call?",

                onNo: null,
                onYes: () => this.leaveAndGoHome(),
            });
        } else {
            return this.leaveAndGoHome();
        }
    };

    toggle(e) {
        // Transitions are not cross-platform compatible,
        // so we implement it directly in JavaScript.
        const resize = (shrink) => {
            const incr = loggedIn ? 9 : 5;
            const lower = 0;
            const upper = 800;

            const menu = document.getElementById("menu");
            let offsetHeight;

            let oldHeight = menu.style.maxHeight || `${shrink ? upper : lower}px`;
            oldHeight = Number.parseInt(oldHeight.substring(0, oldHeight.length - 2));

            const continueResize = (shrink && oldHeight > lower) || (!shrink && oldHeight < upper);

            if (continueResize) {
                const height = shrink ? Math.max(oldHeight - incr, lower) :
                    Math.min(oldHeight + incr, upper);

                offsetHeight = menu.offsetHeight;
                menu.style.maxHeight = `${height}px`;

                if (offsetHeight !== menu.offsetHeight) {
                    setTimeout(() => {
                        resize(shrink);
                    }, 5);
                } else {
                    menu.style.maxHeight = `${shrink ? lower : upper}px`;
                }
            }
        };

        const { activeTab, isOpen, loggedIn } = this.state;

        const menuBtn = document.getElementById("menu-btn");

        if (isOpen) {
            menuBtn.classList.add("is-inactive");
            menuBtn.classList.remove("is-active");
        } else {
            menuBtn.classList.remove("is-inactive");
            menuBtn.classList.add("is-active");
        }

        const menu = document.getElementById("menu");
        menu.style.maxHeight = isOpen ? `${menu.offsetHeight}px` : menu.style.maxHeight;

        resize(isOpen);

        this.setState({
            isOpen: !isOpen,
            activeTab: isOpen ? -1 : activeTab,
        });
    }

    expandTab(tab) {
        if (tab === this.state.activeTab) {
            this.setState({activeTab: -1});
        } else {
            this.setState({activeTab: tab});
        }
    }

    render() {
        const {activeTab, gotLoggedIn, goToChat, loggedIn, onMobile, returnToHome} = this.state;

        if (goToChat) {
            const {chatParams} = this.state;
            const mapping = {
                friends: "conversation",
                dating: "relationship",
                collaboration: "collaborate"
            };

            let {chatType} = chatParams;
            chatType = mapping[chatType] || chatType;

            const destination = `/chat/${chatType}`;

            if (this.props.location.pathname !== destination) {
                return <Redirect to={{pathname: destination, state: {chatParams}}} />;
            }
        }

        if (returnToHome) {
            this.setState({returnToHome: false});
            return <Redirect to="/" />;
        }

        const headerPadding = "24px";//onMobile ? `0 ${THEME.marginXSmall}` : `0 ${THEME.marginSmall}`
        const logoHeader = (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: THEME.colorAccent,
                    marginLeft: onMobile ? THEME.marginXSmall : THEME.marginSmall,
                    padding: headerPadding,
                    height: "100%"}}>
                <img style={{width: "233px", height:"45px", marginTop: "-10px"}} src={logo2} alt="logo-header" />
            </div>
        );
        const logoLink = (
            <Link to="/" style={{height: "100%"}}>
                {logoHeader}
            </Link>
        );

        const wrapperClass = `nav-wrapper w-fill ${this.props.acceptedCookies ? "" : "no-ok"}`;

        let hamburgerInner = undefined;
        let modals = undefined;
        let menuInner = undefined;

        const hamburgerRightPadding = onMobile ? THEME.marginXSmall : THEME.marginSmall;

        if (!gotLoggedIn) {

        } else if (loggedIn) {
            const onMobile2 = window.innerWidth <= 700;
            modals = <>
            <Modal id="talkSchedule" isOpen={this.state.modal} style={{
                height: "auto",
                ...(onMobile ? {} : {width: "750px"})
            }}>
                <X stroke="white" id="close-modal" size="25" onClick={() => {
                    this.setState({modal: false}, () => {
                        if (this.state.modalCb) {
                            this.state.modalCb();
                        }
                    });
                }} />
                <h1 style={window.innerWidth <= 700 ? {
                    paddingLeft: "50px",
                    paddingRight: "50px",
                } : {}} className="chat-modal">{this.state.modalMsg}</h1>
            </Modal>

            <Modal id="talkSchedule" isOpen={this.state.modal2} style={{
                height: "auto",
                padding: "10px 10px 10px 0",
                backgroundColor: "#9FC6CC",
                ...(onMobile ? {} : {width: "350px"})
            }}>
                <h1 style={onMobile2 ? {
                    paddingLeft: "50px",
                    paddingRight: "50px",
                } : {}}>{this.state.modal2Msg}</h1>

                <div style={{
                    display: "flex",
                    textAlign: "center",
                    flexDirection: "column",

                    paddingLeft: "70px",
                    paddingRight: "70px",
                }}>
                    <div id="request-options" style={{
                        paddingTop: onMobile2 ? "5px" : "35px",
                        paddingBottom: "20px",
                        justifyContent: "center",
                    }}>
                        <div className="btn reschedule-option"
                            onClick={() => {
                                this.handleYesNo(true);
                            }}>YES
                        </div>
                        <div className="btn reschedule-option"
                            onClick={() => {
                                this.handleYesNo(false);
                            }}>NO
                        </div>
                    </div>
                </div>
            </Modal>
          </>;

            menuInner = <>
            {/*<Link to="/events" className="tab">EVENTS</Link>*/}
            <Link to="/conversation" className="tab">CONVERSATION</Link>
            <Link to="/collaborate" className="tab">COLLABORATE</Link>
            {this.state.isDatingAccount &&
                <Link to="/relationship" className="tab">RELATIONSHIP</Link>}

            <div style={{display: "grid", gridAutoFlow: "column", gap: "22px", marginRight: "5px", marginLeft: "20px"}}>
                <MessengerDropdown isDatingAccount={this.state.isDatingAccount}/>
                <Notifications />
                <SettingsDropdown logout={this.logout}/>
            </div>
          </>;

            hamburgerInner = <>
            {/*<Link to="/events"*/}
            {/*    onClick={() => this.toggle()}*/}
            {/*    className="nav-menu-item header events"*/}
            {/*>*/}
            {/*    EVENTS*/}
            {/*</Link>*/}
            <Link to="/conversation"
                onClick={() => this.toggle()}
                className="nav-menu-item header"
            >
                CONVERSATION
            </Link>
            <Link to="/collaborate"
                onClick={() => this.toggle()}
                className="nav-menu-item header"
            >
                COLLABORATE
            </Link>
            {this.state.isDatingAccount &&
            <Link to="/relationship"
                onClick={() => this.toggle()}
                className="nav-menu-item header"
            >
                RELATIONSHIP
            </Link>
            }
            <MessengerDropdown menu
                isOpen={activeTab === 0}
                // closeMenu={() => this.toggle()}
                toggle={() => this.expandTab(0)}
                isDatingAccount={this.state.isDatingAccount} />
            <Notifications menu
                isOpen={activeTab === 1}
                closeMenu={() => this.toggle()}
                toggle={() => this.expandTab(1)} />
            <ProfileDropdown menu
                isOpen={activeTab === 2}
                closeMenu={() => this.toggle()}
                toggle={() => this.expandTab(2)} />
            <Link to="/settings"
                onClick={() => this.toggle()}
                className="nav-menu-item header"
            >
                SETTINGS
            </Link>
            <div className="nav-menu-item header logout"
                onClick={this.logout}
            >
                LOGOUT
            </div>
          </>;
        } else {
            menuInner = <>
            <Link to="/what-is-morphkey" className="tab">WHAT IS MORPHKEY?</Link>
            {/*<Link to="/events" className="tab">EVENTS</Link>*/}
            <Link to="/login" onClick={() => this.checkLogin()}
                className="tab">
                LOGIN
            </Link>
            <Link to="/register" className="tab">
                SIGN UP
            </Link>
          </>;

            hamburgerInner = <>
            <Link to="/what-is-morphkey" className="nav-menu-item">WHAT IS MORPHKEY?</Link>
            {/*<Link to="/events" onClick={() => this.toggle()}*/}
            {/*    className="nav-menu-item">EVENTS</Link>*/}
            <Link to="/login" onClick={() => {
                this.checkLogin();
            }} className="nav-menu-item">
                LOGIN
            </Link>
            <Link to="/register" onClick={() => this.toggle()}
                className="nav-menu-item">
                SIGN UP
            </Link>
          </>;

        }

        // Decide when to show the hamburger menu
        const showHamburger = (loggedIn && (window.innerWidth < 1070)) || (window.innerWidth < 720);
        const hamburgerDisplay = {display: showHamburger ? "unset" : "none"};
        const menuDisplay = {display: showHamburger ? "none" : "grid"};

        // checkpoint the row-based menu so it can decrease in width
        const decreaseSpacing1 = window.innerWidth < 1110;
        const decreaseSpacing2 = window.innerWidth < 1200;
        let menuSpacing = {letterSpacing: "2px", columnGap: "35px"};
        if (gotLoggedIn && loggedIn) {
            if (decreaseSpacing1) {
                menuSpacing = {letterSpacing: "normal", columnGap: "20px"};
            } else if (decreaseSpacing2) {
                menuSpacing = {letterSpacing: "1px", columnGap: "24px"};
            }
        }

        const html = <>
        { modals }
          <div className={wrapperClass} id="nav">
              <div className="nav-inner row-apart w-fill" style={{paddingRight: "2px"}}>
                  {logoLink}

                  <button className="hamburger hamburger--squeeze"
                      id="menu-btn"
                      onClick={() => this.toggle()}
                      type="button"
                      style={{paddingRight: hamburgerRightPadding, ...hamburgerDisplay}}
                  >
                      <span className="hamburger-box">
                          <span className="hamburger-inner" />
                      </span>
                  </button>
                  <div
                      id="nav-item-wrapper"
                      style={{
                          ...menuDisplay,
                          gridAutoFlow: "column",
                          padding: `0px ${THEME.marginSmall}`,
                          alignContent: "center",
                          alignItems: "center",
                          color: THEME.colorAccent,
                          ...menuSpacing}}>
                      {menuInner}
                  </div>

                  {/* This is for the mobile UI */}
                  <div className="nav-menu-overlay shadow" id="menu" style={{...hamburgerDisplay}}>
                      {hamburgerInner}
                  </div>
              </div>
          </div>
        </>;


        // const getCustomColorButton = (id, text) => (
        //     <div className={`btn ${id.toLowerCase()}`} id={id}>
        //         {text}
        //     </div>
        // );

        // const loginButton = getCustomColorButton("loginButton", "LOG IN");
        // const signupButton = getCustomColorButton("signupButton", "SIGN UP");

        return html;

    }
}
Header.contextType = CallContext;

export default withRouter(Header);
